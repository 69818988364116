import {Translation} from "./ru";

export const englishTranslation: Translation = {
    "Website Logo": "Website Logo",
    "Featured": "Featured",
    "Sign In": "Sign In",
    "Daily rent from owners all over Belarus": "Daily rent from owners all over Belarus", //Belarus for english-speakers
    "You can rent apartment": "On the XXX website you can rent an apartment for a day for work and leisure, rent a house and estate for a country holiday, find with us the most advantageous offers for daily rent of apartments and estates without intermediaries",
    "Gomel": "Gomel",
    "Brest": "Brest",
    "Grodno": "Grodno",
    "Pinsk": "Pinsk",
    "Minsk": "Minsk",
    "Mogilev": "Mogilev",
    "Find": "Find",
    "Apartments": "Apartments",
    "Houses": "Houses",
    "Guests": "Guests",
    "guest_zero": "{{count}} guests",
    "guest_one": "{{count}} guest",
    "guest_few": "{{count}} guests",
    "guest_many": "{{count}} guests",
    "Filters": "Filters",
    "From": "from",
    "To": "to",
    "Price": "Price",
    "Area": "Area",
    "Amenities": "Amenities",
    "Nothing Found": "Nothing found",
    "Remove Filters": "Remove filters",
    "Price Low To High": "Price low to high",
    "Price High To Low": "Price high to low",
    "Popularity Low To High": "Popularity low to high",
    "Popularity High To Low": "Popularity high to low",
    "Show Contacts": "Show contacts",
    "Rooms": "{{count}} rooms",
    "Create Apartment": "Create apartment",
    "Title": "Title",
    "Apartment Description": "Apartment description",
    "Rooms Quantity": "Rooms quantity",
    "Beds Quantity": "Beds quantity",
    "Quests Quantity": "Quests quantity",
    "Currency": "Currency",
    "Address": "Address",
    "Photos": "Photos",
    "Add": "Добавить",
    "Save": "Save",
    "Share": "Share",
    "Day": "day",
    "Leave Request": "Leave a request",
    "Book Apartment": "Book The Apartment",
    "Enter Your Name": "Enter your name",
    "Name": "Name",
    "Phone": "Phone",
    "Email": "E-mail",
    "Number Of People": "Number Of People",
    "Check-in Date": "Check-in date",
    "Check-out Date": "Check-out date",
    "Send Request": "Send a request",
    "Additional Information": "Additional information",
    "Room_zero": "Rooms",
    "Room_one": "Room",
    "Room_few": "Rooms",
    "Room_many": "Rooms",
    "Bed_zero": "Beds",
    "Bed_one": "Bed",
    "Bed_few": "Beds",
    "Bed_many": "Beds",
    "Guest_zero": "Guests",
    "Guest_one": "Guest",
    "Guest_few": "Guests",
    "Guest_many": "Guests",
    "On Map": "On the map",
    "Description": "Description",
    "Rules Of Residence": "Rules of residence",
    "Near The House": "Near the house",
    "Reviews": "Reviews",
    "House Description": "House description",
    "In The Kitchen": "In the Kitchen",
    "In The Bathroom": "In the Bathroom",
    "Entertainment And Multimedia": "Entertainment and multimedia",
    "Security": "Security",
    "Laundry And Linen": "Laundry and linen",
    "Amenities Outside": "Удобства Снаружи",
    "Methods Of Payments": "Methods Of Payments",
    "Show More": "Show more",
    "Sign Up": "Sign Up",
    "Password": "Password",
    "User With That Name Already Exists": "User with that name already exists",
    "User With That Email Already Exists": "User with that email already exists",
    "Incorrect Password Or Username": "Incorrect password or username",
    "Delete": "Delete",
    "Update Apartment Data": "Update apartment data",
    "Orders": "Orders",
    "Long Term Rental": "Long term rental",
    "Cancel": "Cancel",
    "Confirm": "Confirm",
    "Approve Order": "Approve Order?",
    "Approve": "Approve",
    "Definitely reject The Order": "Definitely reject the order?",
    "Reject": "Reject",
    "Definitely Sign Out?": "Definitely sign out?",
    "Sign Out": "Sign out",
    "Definitely Delete Apartment": "Definitely delete apartment?",
    "Yes": "Yes",
    "Order Approved": "Order approved",
    "Order Pending": "Order is pending",
    "Order Rejected": "Order rejected",
    "Tariffs": "Tariffs",
    "Some error has occurred": "Some error has occurred",
    "Choose tariff": "Choose tariff",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Definitely delete tariff?": "Definitely delete tariff?",
    "Price in monday": "Price in monday",
    "Price in tuesday": "Price in tuesday",
    "Price in wednesday": "Price in wednesday",
    "Price in thursday": "Price in thursday",
    "Price in friday": "Price in friday",
    "Price in saturday": "Price in saturday",
    "Price in sunday": "Price in sunday",
    "Sleep places": "Sleep places",
    "Order is successfully submitted": "Order is successfully submitted",
    "Adults": "Adults",
    "Teens": "Teens",
    "Childrens": "Childrens",
    "Babies": "Babies",
    "Pets": "Pets",
    "18 years": "18 years",
    "13-17 years": "13-17 years",
    "2-12 years": "2-12 years",
    "Younger than 2 years": "Younger than 2 years",
    "Total": "Total",
    "Guest Quantity": "Guest quantity",
    "Required Field": "Required Field",
    "Invalid phone": "Неверный телефон",
    "Please Select Dates": "Please select dates",
    "Calendar": "Calendar",
    "Booked": "Booked",
    "Minimum Booking Period: 2 Nights": "Minimum booking period: 2 nights",
    "Check in": "Check in",
    "Check out": "Check out",
    "Order_one": "order",
    "Order_many": "orders",
    "Order_few": "orders",
    "Expand Description": "Expand Description",
    "Collapse Description": "Collapse Description",
    "Adult_zero": "adults",
    "Adult_one": "adult",
    "Adult_few": "adults",
    "Adult_many": "adults",
    "Kid_zero": "kids",
    "Kid_one": "kid",
    "Kid_few": "kids",
    "Kid_many": "kids",
    "Change Guests Pricing": "Change Prices",
    "Adult Price": "Adult Price",
    "Teen Price": "Teen Price",
    "Kid Price": "Kid Price",
    "Baby Price": "Baby Price",
    "Pet Price": "Pet Price",
    "Are Pets Allowed?": "Is pets allowed?",
    "Select Amenities": "Select Amenities",
    "Saved": "Saved",
};

/*

guest count
guest's count

 */