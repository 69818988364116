export const russianTranslation = {
    "Website Logo": "Лого вебсайта",
    "Featured": "Избранное",
    "Sign In": "Войти",
    "Daily rent from owners all over Belarus": "Посуточная аренда от владельцев по всему Гомелю",
    "You can rent apartment": "На сайте Элит Тауэр вы можете легко и удобно снять квартиру на сутки для работы или отдыха. Мы предлагаем широкий выбор жилья в различных районах, чтобы вы могли выбрать идеальный вариант. Наши предложения актуальны и без посредников, что гарантирует лучшие цены и прозрачные условия. Найдите свою идеальную квартиру на Элит Тауэр!",
    "Gomel": "Гомель",
    "Brest": "Брест",
    "Grodno": "Гродно",
    "Pinsk": "Пинск",
    "Minsk": "Минск",
    "Mogilev": "Могилёв",
    "Find": "Найти",
    "Apartments": "Квартиры",
    "Houses": "Усадьбы",
    "Guests": "Гости",
    "guest_zero": "{{count}} гостей",
    "guest_one": "{{count}} гость",
    "guest_few": "{{count}} гостя",
    "guest_many": "{{count}} гостей",
    "Filters": "Фильтры",
    "From": "от",
    "To": "до",
    "Price": "Стоимость",
    "Area": "Площадь",
    "Amenities": "Удобства",
    "Nothing Found": "Ничего не найдено",
    "Remove Filters": "Сбросить все фильтры",
    "Price Low To High": "Сначала дешевле",
    "Price High To Low": "Сначала дороже",
    "Popularity Low To High": "Сначала популярнее",
    "Popularity High To Low": "Сначала не популярнее",
    "Show Contacts": "Показать контакты",
    "Rooms": "{{count}}-комн.",
    "Create Apartment": "Добавить квартиру",
    "Title": "Название",
    "Apartment Description": "Описание квартиры",
    "Rooms Quantity": "Количество комнат",
    "Beds Quantity": "Количество кроватей",
    "Quests Quantity": "Количество гостей",
    "Currency": "Валюта",
    "Address": "Адрес",
    "Photos": "Фотографии",
    "Add": "Добавить",
    "Save": "Сохранить",
    "Saved": "Сохранено",
    "Share": "Поделится",
    "Day": "сутки",
    "Leave Request": "Оставить заявку",
    "Book Apartment": "Забронировать квартиру",
    "Enter Your Name": "Введите ваше имя",
    "Name": "Имя",
    "Phone": "Телефон",
    "Email": "E-mail",
    "Number Of People": "Количество человек",
    "Check-in Date": "Дата заезда",
    "Check-out Date": "Дата отъезда",
    "Send Request": "Отправить Заявку",
    "Additional Information": "Дополнительно",
    "Room_zero": "Комнат",
    "Room_one": "Комната",
    "Room_few": "Комнаты",
    "Room_many": "Комнат",
    "Bed_zero": "Кроватей",
    "Bed_one": "Кровать",
    "Bed_few": "Кровати",
    "Bed_many": "Кроватей",
    "Guest_zero": "Гостей",
    "Guest_one": "Гость",
    "Guest_few": "Гостя",
    "Guest_many": "Гостей",
    "On Map": "На карте", //TODO rename to On The map, and in the future always use articles it translations keys
    "Description": "Описание",
    "Rules Of Residence": "Правила проживания",
    "Near The House": "Рядом с жилищем",
    "Reviews": "Отзывы",
    "House Description": "Описание дома",
    "In The Kitchen": "На кухне",
    "In The Bathroom": "В ванной",
    "Entertainment And Multimedia": "Развлечения и мультимедиа",
    "Security": "Безопасность",
    "Laundry And Linen": "Стирка и белье",
    "Amenities Outside": "Удобства снаружи",
    "Methods Of Payments": "Способы оплаты",
    "Show More": "Показать больше",
    "Sign Up": "Регистрация",
    "Password": "Пароль",
    "User With That Name Already Exists": "Пользователь с этим именем уже существует",
    "User With That Email Already Exists": "Пользователь с этой почтой уже существует",
    "Incorrect Password Or Username": "Неверный пароль или имя пользователя",
    "Delete": "Удалить",
    "Update Apartment Data": "Обновите информацию о квартире",
    "Orders": "Заказы",
    "Long Term Rental": "Аренда на длительный срок",
    "Cancel": "Отмена",
    "Confirm": "Подтвердить",
    "Approve Order": "Подтвердить заказ?",
    "Approve": "Подтвердить",
    "Definitely reject The Order": "Точно отклонить заказ?",
    "Reject": "Отклонить",
    "Definitely Sign Out?": "Точно выйти?",
    "Sign Out": "Выйти",
    "Definitely Delete Apartment": "Точно удалить квартиру?",
    "Yes": "Да",
    "Order Approved": "Заказ подтверждён",
    "Order Pending": "Заказ ожидает подтверждения",
    "Order Rejected": "Заказ отклонён",
    "Tariffs": "Тарифы",
    "Some error has occurred": "Произошла какая-то ошибка",
    "Choose tariff": "Выберите тариф",
    "Monday": "Понедельник",
    "Tuesday": "Вторник",
    "Wednesday": "Среда",
    "Thursday": "Четверг",
    "Friday": "Пятница",
    "Saturday": "Суббота",
    "Sunday": "Воскресенье",
    "Definitely delete tariff?": "Точно удалить тариф?",
    "Price in monday": "Цена в понедельник",
    "Price in tuesday": "Цена в вторник",
    "Price in wednesday": "Цена в среду",
    "Price in thursday": "Цена в четверг",
    "Price in friday": "Цена в пятницу",
    "Price in saturday": "Цена в субботу",
    "Price in sunday": "Цена в воскресенье",
    "Sleep places": "Спальных мест",
    "Order is successfully submitted": "Заказ успешно отправлен",
    "Adults": "Взрослые",
    "Teens": "Подростки",
    "Childrens": "Дети",
    "Babies": "Младенцы",
    "Pets": "Питомцы",
    "18 years": "От 18 лет",
    "13-17 years": "13-17 лет",
    "2-12 years": "2-12 лет",
    "Younger than 2 years": "Младше 2 лет",
    "Total": "Итого",
    "Guest Quantity": "Количество гостей",
    "Required Field": "Обязательное поле",
    "Invalid phone": "Неправильный телефон",
    "Please Select Dates": "Выберите даты",
    "Calendar": "Календарь",
    "Booked": "Забронировано",
    "Minimum Booking Period: 2 Nights": "Минимальный промежуток бронирования: 2 ночи",
    "Check in": "Заезд",
    "Check out": "Выезд",
    "Order_one": "заказ",
    "Order_few": "заказа",
    "Order_many": "заказов",
    "Expand Description": "Развернуть описание",
    "Collapse Description": "Свернуть описание",
    "Adult_zero": "взрослых",
    "Adult_one": "взрослый",
    "Adult_few": "взрослых",
    "Adult_many": "взрослых",
    "Kid_zero": "детей",
    "Kid_one": "ребёнок",
    "Kid_few": "ребёнка",
    "Kid_many": "детей",
    "Change Guests Pricing": "Изменить цены за гостей",
    "Adult Price": "Цена за Взрослого",
    "Teen Price": "Цена за Подростка",
    "Kid Price": "Цена за Ребёнка",
    "Baby Price": "Цена за Младенца",
    "Pet Price": "Цена за Питомца",
    "Are Pets Allowed?": "Разрешены ли питомцы?",
    "Select Amenities": "Выберите Удобства"
};

export type Translation = typeof russianTranslation;

